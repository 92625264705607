import React from 'react';

const getPaginationRange = (currentPage, totalPages, siblingCount = 1) => {
  const totalPageNumbers = siblingCount * 2 + 5;

  if (totalPages <= totalPageNumbers) {
    return Array.from({ length: totalPages }, (_, idx) => idx + 1);
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

  const showLeftDots = leftSiblingIndex > 2;
  const showRightDots = rightSiblingIndex < totalPages - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPages;

  if (!showLeftDots && showRightDots) {
    let leftRange = Array.from({ length: 3 + 2 * siblingCount }, (_, idx) => idx + 1);
    return [...leftRange, '...', totalPages];
  }

  if (showLeftDots && !showRightDots) {
    let rightRange = Array.from(
      { length: 3 + 2 * siblingCount },
      (_, idx) => totalPages - (2 + 2 * siblingCount) + idx
    );
    return [firstPageIndex, '...', ...rightRange];
  }

  if (showLeftDots && showRightDots) {
    let middleRange = Array.from(
      { length: 2 * siblingCount + 1 },
      (_, idx) => leftSiblingIndex + idx
    );
    return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
  }
};

const Pagination = ({ currentPage, totalPages, siblingCount = 1, onPageChange }) => {
  const paginationRange = getPaginationRange(currentPage, totalPages, siblingCount);

  if (totalPages === 0) return null;

  return (
    <div className="pagination d-flex justify-content-center gap-1">
      {paginationRange.map((page, index) => {
        if (page === '...') {
          return (
            <button
              key={index}
              disabled
              className="btn btn-pagination btn-disabled"
            >
              ...
            </button>
          );
        }

        return (
          <button
            key={index}
            onClick={() => onPageChange(page)}
            className={`btn btn-pagination ${
              currentPage === page ? 'btn-primary apt-bg-color-1 border-0' : 'btn-secondary border-0'
            }`}
          >
            {page}
          </button>
        );
      })}
    </div>
  );
};

export default Pagination;
  