import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchFormWithFilters from '../components/SearchFormWithFilters';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaStar, FaGlobe } from 'react-icons/fa';
import defaultImage from '../images/default-image.jpg';
import Pagination from './Pagination';
import Popup2 from './Popup2';
import { FaInfoCircle } from 'react-icons/fa';


const SearchPage = () => {
  const [strutture, setStrutture] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  // Recupero parametri dalla query string
  const queryParams = new URLSearchParams(location.search);
  const nome_completo = queryParams.get('nome_completo') || "";
  const categoria = queryParams.get('categoria') || "";
  const tipologia = queryParams.get('tipologia') || "";
  const comune = queryParams.get('comune') || "";
  const prezzo = queryParams.get('prezzo') || "";
  const pos_interna = queryParams.get('pos_interna') || "";
  const pos_esterna = queryParams.get('pos_esterna') || "";
  const pos_aree_mt = queryParams.get('pos_aree_mt') || "";
  const area_geografica = queryParams.get('area_geografica') || "";
  const classificazione = queryParams.get('classificazione') || "";
  const destinazione = queryParams.get('destinazione') || "";
  const denominazione = queryParams.get('denominazione') || "";
  const sort = queryParams.get('sort') || "default"; //DEFAULT
  const classificazioniValide = ["5 Stelle", "4 Stelle", "3 Stelle", "2 Stelle", "1 stella"];


  const [isPopupOpen, setIsPopupOpen] = useState(false);


  useEffect(() => {
    setCurrentPage(1);

  }, [
    nome_completo,
    categoria,
    tipologia,
    comune,
    prezzo,
    pos_interna,
    pos_esterna,
    pos_aree_mt,
    area_geografica,
    classificazione,
    destinazione,
    denominazione,
    sort

  ]);


  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };


  const resetFilters = () => {
    navigate(`/search`);
    window.location.reload(); // Forza il refresh della pagina

  };


  useEffect(() => {
    setLoading(true);


    // Costruzione URL richiesta API
    let url = `https://sist.aptbasilicata.it/api/strutture?page=${currentPage}`;


    // Aggiunta parametri di query se presenti
    const params = new URLSearchParams();
    if (nome_completo) params.append('nome_completo', nome_completo);
    if (categoria) params.append('categoria', categoria);
    if (tipologia) params.append('tipologia', tipologia);
    if (comune) params.append('comune', comune);
    if (prezzo) params.append('prezzo', prezzo);
    if (classificazione) params.append('classificazione', classificazione);
    if (pos_interna) params.append('pos_interna', pos_interna);
    if (pos_esterna) params.append('pos_esterna', pos_esterna);
    if (pos_aree_mt) params.append('pos_aree_mt', pos_aree_mt);
    if (area_geografica) params.append('area_geografica', area_geografica);
    if (destinazione) params.append('comune', destinazione);
    if (denominazione) params.append('nome_completo', denominazione);
    if (sort) params.append('sort', sort);


    if (params.toString()) {
      url += `&${params.toString()}`;
    }



    axios.get(url)
      .then((response) => {
        const data = response.data.structures;
        setStrutture(data.data || []);
        setTotalPages(data.last_page);
        setTotalResults(data.total);  // Imposta il numero totale dei risultati
        setLoading(false);
      })
      .catch((err) => {
        setError("Si è verificato un errore durante la ricerca.");
        setLoading(false);
      });
  }, [nome_completo, categoria, tipologia, comune, prezzo, pos_interna, pos_esterna, pos_aree_mt, area_geografica, classificazione, destinazione, denominazione, sort, currentPage]);

  const handleCardClick = (id) => {
    navigate(`/strutture?id=${id}`);
  };






  const handlePageChange = (page) => {
    setCurrentPage(page);

    // Logica per caricare i dati per la nuova pagina
  };


  // Helper per trovare l'immagine di copertina
  const getCoverImage = (fotos) => {
    const coverImage = fotos?.find(foto => foto.is_cover === 1);
    return coverImage ? `https://sist.aptbasilicata.it/${coverImage.path}` : defaultImage;
  };



  return (
    <>
      <section className='apt-bg-color-2 pt-2 pb-3 bg-home mb-4'>
        <div className='container mt-4'>
          <div>
            <h1 className='title'>Dove dormire</h1>
            <p className='text-white fs-5'> Ricerca tutte le strutture ricettive della Basilicata</p>
          </div>
        </div>
      </section>

      <div className='container'>
        <div className='row'>

          <div className="col-md-4">

            <SearchFormWithFilters
              initialQuery={nome_completo}
              initialCategory={categoria}
              initialType={tipologia}
              initialComune={comune}
              initialPrezzo={prezzo}
              initialClassificazione={classificazione}
              initialPosInterna={pos_interna}
              initialPosEsterna={pos_esterna}
              initialPosAree={pos_aree_mt}
              initialPosAreeGeo={area_geografica}
              initialOrdina={sort}
              initialFilter={currentPage}
              initialDestinazione={destinazione}
              initialDenominazione={denominazione}
            />


          </div>

          <div className="col-md-8 mt-4">
            {/* Mostra il numero totale dei risultati */}

            <div className='row align-items-center'>
              <div className="col-6">
                {totalResults > 0 && (
                  <span className="card-tipologia">
                    Trovati {totalResults.toLocaleString('it-IT')} risultati
                  </span>
                )}
              </div>

              <div className="col-6 text-right d-flex flex-row-reverse">

                <button
                  type="button"
                  className="btn btn-nuova mt-auto apt-bg-color-1 border-0 "
                  onClick={resetFilters}
                >
                  Nuova ricerca
                </button>
              </div>

            </div>

            {loading && <p>Caricamento...</p>}
            {error && <p>{error}</p>}
            {strutture.length > 0 ? (
              <div className="row mb-2">
                {strutture.map((struttura) => (
                  <div className="col-md-12 m-0 p-0">
                    <div
                      className="card flex-md-row box-shadow mt-4 shadow-sm border struttura-result m-0 p-0"
                    // onClick={() => handleCardClick(struttura.id)}
                    // style={{ cursor: 'pointer' }}
                    >

                      {/* Immagine */}
                      <div
                        className="rounded col-sm-4 tumb-image"
                        style={{
                          backgroundImage: `url(${getCoverImage(struttura.fotos)})`,
                          cursor: 'pointer'
                        }}
                        onClick={() => handleCardClick(struttura.id)}
                      />


                      <div className="card-body card-tipologia">
                        {/* Classificazione e dettagli */}

                        {(struttura.classificazione_alb === "Non soggetta a classificazione") ||

                          (struttura.classificazione_alb !== classificazioniValide) ? (

                          <span className='result_tipologia'> {struttura.tipologia || 'Non disponibile'} </span>
                        ) : (
                          struttura.classificazione_alb && <>{struttura.classificazione_alb}</>
                        )}


                        {struttura.classificazione_alb === "5 Stelle Lusso" && (
                          <span className='result_tipologia' >
                            {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /><br />
                          </span>
                        )}


                        {struttura.classificazione_alb === "5 Stelle" && (
                          <span className='result_tipologia'>
                            {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /><br />
                          </span>
                        )}


                        {struttura.classificazione_alb === "4 Stelle" && (
                          <span className='result_tipologia'>
                            {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /><br />
                          </span>
                        )}


                        {struttura.classificazione_alb === "3 Stelle" && (
                          <span className='result_tipologia'>
                            {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' />
                          </span>
                        )}


                        {struttura.classificazione_alb === "2 Stelle" && (
                          <span className='result_tipologia'>
                            {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' />
                          </span >
                        )}


                        {struttura.classificazione_alb === "1 Stelle" && (
                          <span className='result_tipologia'>
                            {struttura.classificazione_alb} <FaStar className='star' />
                          </span>
                        )}
                        <h5 className="card-title fs-5 bold-text apt-color-1"

                          style={{
                            cursor: 'pointer'

                          }}
                        >{struttura.nome_completo}


                        </h5>


                        <div className="d-flex flex-column gap-1">
                          <span><FaMapMarkerAlt className="apt-color-1" /> {struttura.indirizzo}{struttura.civico && struttura.civico !== '/' ? `, ${struttura.civico}` : ''}
                            <br></br> {struttura.cap} {struttura.rel_comune?.COMUNE || 'Non disponibile'} ({struttura.rel_comune?.PROV})</span>
                          <span><FaPhoneAlt className="apt-color-1" /> {struttura.cell || struttura.tel || 'Non disponibile'}</span>
                          <span><FaEnvelope className="apt-color-1" /> {struttura.email || 'Non disponibile'}</span>
                          {struttura.sitoweb !== null && (
                            <span><FaGlobe className="apt-color-1" /> {struttura.sitoweb || 'Non disponibile'}</span>
                          )}

                        </div>

                        <div className="d-flex flex-row gap-1 mt-1">
                          {struttura.camere_numero !== 0 && (
                            <span>
                              <span>Camere:</span> <span className='card-prezzo'>{struttura.camere_numero}</span><br />
                            </span>
                          )}
                          {struttura.suite_numero !== 0 && (
                            <span>
                              <span>Suite:</span> <span className='card-prezzo'>{struttura.suite_numero}</span><br />
                            </span>
                          )}
                        </div>

                        <div className="d-flex flex-row gap-0">
                          {struttura.tot_posti_letto_tot !== 0 && (
                            <span>
                              <span >Posti letto:</span> <span className='card-prezzo'>{struttura.tot_posti_letto_tot}</span><br />
                            </span>
                          )}
                          {struttura.tot_posti_letto_aggiungibili !== 0 && (
                            <span>
                              <span className='card-prezzo'> &nbsp; (+{struttura.tot_posti_letto_aggiungibili} agg.) </span> <br />
                            </span>
                          )}
                        </div>

                        <div className="d-flex flex-column gap-1 mt-1">
                          <span className="card-tipologia"> CIN: <span className="card-prezzo">{struttura.cin || 'Non disponibile'}</span> </span>
                        </div>



                        <div className="d-grid gap-1 d-md-block mt-3">
                          <button
                            type="button"
                            className="btn btn-struttura mt-auto apt-bg-color-1 border-0"
                            onClick={() => handleCardClick(struttura.id)}

                          >
                            Visualizza struttura
                          </button>
                        </div>
                      </div>


                      <div className='col-sm-3 m-0 p-0 divpopup'>

                        <iframe className='mappa'

                          title={struttura.denominazione}
                          src={`https://maps.google.it/maps?q=${struttura.indirizzo} ${struttura.rel_comune?.COMUNE} ${struttura.civico} &output=embed`}></iframe>

                        <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={togglePopup}>
                          <FaInfoCircle style={{ fontSize: '14px', color: '#004F78', marginRight: '2px' }} />
                          <span className='info' style={{ color: '#333', fontSize: '13px' }}>
                            Prezzo appl.: {struttura.prezzi_max}
                          </span>


                          {isPopupOpen && (
                            <Popup2 onClose={togglePopup}>
                              <h2 className='apt-color-1'>Avvertenze</h2>
                              <p>Attraverso l’utilizzo del filtro “Prezzo” è possibile selezionare le strutture ricettive attraverso il loro prezzo massimo dichiarato all’APT.<br></br>
                                Si fa presente che il sistema di ricerca individua solo il prezzo maggiore tra tutti quelli massimi dichiarati dalla struttura ricettiva all’APT, a prescindere se il predetto prezzo è riferito a camera, suite o unità abitativa, o al pernottamento per uno o più giorni (come ad esempio quelle strutture ricettive che offrono soggiorni settimanali/mensili), o ad altre opzioni (posti letto, servizi, ecc.).<br></br>
                                Ad esempio, se una struttura ricettiva che ha 10 camere, ha dichiarato che per 9 di esse il prezzo massimo applicabile è di 80,00 euro e per una, la restante, è di 120,00 euro, il sistema mostrerà la struttura solo tra quelle il cui prezzo di riferimento è quello di 120,00 euro e non di 80,00 euro, motivo per cui visualizzerà la struttura solo tra quelle che praticano prezzi massimi “da 101 euro a 200 euro” escludendola nell’altra opzione “da 51 euro a 100 euro”, pur praticando, come già detto, per 9 camere sulle 10 totali il prezzo a 80,00 euro.</p>
                              <button className='btn btn-struttura' onClick={togglePopup} style={{ padding: '10px 20px', fontSize: '16px' }}>
                                Chiudi
                              </button>
                            </Popup2>
                          )}
                        </div>

                      </div>


                    </div>
                  </div>
                ))}
              </div>
            ) : (
              !loading && <p>Nessuna struttura trovata.</p>
            )}

            {/* Paginazione */}
            <div className="pagination d-flex justify-content-center gap-1 mt-4 mb-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default SearchPage;
