import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Popup from './Popup';
import { FaInfoCircle } from 'react-icons/fa';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';



const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};


const SearchFormWithFilters = ({ initialQuery, initialComune }) => {
    const [filters, setFilters] = useState({
         nome_completo: initialQuery || "",
         comune: initialComune || "",

        tipologia: [],
        prezzo: [],
        classificazione: [],
        pos_interna: [],
        pos_esterna: [],
        pos_aree_mt: [],
        area_geografica: [],


        //// DESTINAZIONE - DENOMINAZIONE
        // comune: [],
        // nome_completo: [],

    });

    const [sort, setSort] = useState("default"); // Stato separato per il filtro "ORDINA PER"


    const [options, setOptions] = useState({
        tipologie: [],
        prezzi: [],
        classificazioni: [],
        pos_interne: [],
        pos_esterne: [],
        pos_aree: [],
        aree_geografiche: [],

        destinazioni: [],
        denominazioni: [],
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();



    // useEffect(() => {
    //     setCurrentPage(1);
    // }, [filters]);




    //AGGIORNAMENTO ////////////////////


    // Funzione per ottenere i parametri dalla query string della URL
    const getQueryParams = (query) => {
        const params = new URLSearchParams(query);
        const filtersFromQuery = {};
        params.forEach((value, key) => {
            if (key !== 'sort') {
                filtersFromQuery[key] = value.split(',');
            } else {
                setSort(value);  // Imposta il parametro di ordinamento
            }
        });
        return filtersFromQuery;
    };

    // Imposta i filtri iniziali dalla query string quando la pagina cambia
    useEffect(() => {
        const filtersFromQuery = getQueryParams(location.search);
        setFilters(prev => ({
            ...prev,
            ...filtersFromQuery
        }));
    }, [location.search]);  // Rilancia quando la URL cambia


    ///////////////////////




    const updateFilters = useCallback(debounce(() => {
        const params = new URLSearchParams();
        Object.entries(filters).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length) {
                params.append(key, value.join(','));
            } else if (typeof value === 'string' && value.trim()) {
                params.append(key, value.trim());
            }
        });



        //SELEZIONA MULTIPLA PER DESTINAZIONE

        // const updateFilters = useCallback(debounce(() => {
        //     const params = new URLSearchParams();
        //     Object.entries(filters).forEach(([key, value]) => {
        //         if (Array.isArray(value) && value.length) {
        //             // Specifico trattamento per `comune` (destinazione)
        //             if (key === 'comune') {
        //                 params.append(key, value.join(',')); // `comune` accetta più valori
        //             } else {
        //                 params.append(key, value.join(','));
        //             }
        //         } else if (typeof value === 'string' && value.trim()) {
        //             params.append(key, value.trim());
        //         }
        //     });



        axios.get(`https://sist.aptbasilicata.it/api/strutture?${params.toString()}`)
            .then(response => {
                const data = response.data;
                setOptions({
                    tipologie: data.tipologie || [],
                    prezzi: data.prezzi || [],
                    classificazioni: data.classificazioni || [],
                    pos_interne: data.pos_interne || [],
                    pos_esterne: data.pos_esterne || [],
                    pos_aree: data.pos_aree || [],
                    aree_geografiche: data.aree_geografiche || [],

                    destinazioni: data.destinazioni || [],
                    denominazioni: data.denominazioni || [],
                });
            })
            .catch(error => {
                console.error("Errore nel recupero delle opzioni per i filtri:", error);
            });
    }, 200), [filters]);

    useEffect(() => {
        updateFilters();
    }, [updateFilters]);

    const handleCheckboxChange = (filterKey, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterKey]: prevFilters[filterKey].includes(value)
                ? prevFilters[filterKey].filter(item => item !== value)
                : [...prevFilters[filterKey], value],
        }));
    };






    // Navigazione automatica al cambio dei filtri
    useEffect(() => {
        const params = new URLSearchParams();

        // Aggiungi i filtri ai parametri
        Object.entries(filters).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length) {
                params.append(key, value.join(','));
            } else if (typeof value === 'string' && value.trim()) {
                params.append(key, value.trim());
            }
        });

        // Aggiungi il parametro di ordinamento (sort) se non è il valore di default
        if (sort !== "default") {
            params.append("sort", sort);
        }

        navigate(`/search?${params.toString()}`);
    }, [filters, sort, navigate]); // Aggiungi `sort` alle dipendenze






    const handleSearch = (e) => {
        e.preventDefault();
        const queryParams = new URLSearchParams();
        Object.entries(filters).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length) {
                queryParams.append(key, value.join(','));
            } else if (typeof value === 'string' && value.trim()) {
                queryParams.append(key, value.trim());
            }
        });


        // Aggiungi il parametro di ordinamento separatamente
        if (sort !== "default") {
            queryParams.append("sort", sort);
        }

        navigate(`/search?${queryParams.toString()}`);
    };

    const resetFilters = () => {
        setFilters({
            // nome_completo: "",
            // comune: "",

            tipologia: [],
            prezzo: [],
            classificazione: [],
            pos_interna: [],
            pos_esterna: [],
            pos_aree_mt: [],
            area_geografica: [],

            nome_completo: "",
            comune: "",

            
        });
        setSort("default"); // Reimposta il parametro di ordinamento
        setCurrentPage(1);
        navigate(`/search`);
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };




    return (
        <form className="d-flex flex-column mb-0 p-0 input-group-lg bg-filter p-4 col-md-12" role="search" onSubmit={handleSearch}>
            <div className="row">
                <div className="col-md-12 mb-2 input-group-md">
                    <h1 className='apt-color-1 bold-text fs-3 mb-3'> Cerca per:</h1>
                    <span className='apt-color-1 bold-text fs-5'>Destinazione</span>


                    {/* <input
                        className="form-control col-md-6"
                        type="text"
                        placeholder="Cerca per comune..."
                        value={filters.comune}
                        onChange={(e) => setFilters({ ...filters, comune: e.target.value })}
                    /> */}


                    <div className="mb-2">
                        <Select
                            // Rimuovi l'attributo isMulti per permettere solo la selezione singola
                            name="destinazione"
                            placeholder="Seleziona il Comune"
                            options={options.destinazioni.map(option => ({
                                value: option.value,
                                label: `${option.value} (${option.count})`, // Visualizzazione dell'opzione
                            }))}
                            // value={options.destinazioni
                            //     .find(option => option.value === filters.comune) // Filtra per un'unica selezione
                            //     ? {
                            //         value: filters.comune,
                            //         label: `${filters.comune} (${options.destinazioni.find(option => option.value === filters.comune)?.count})`
                            //     }
                            //     : null
                            // }
                            value={filters.comune
                                ? { value: filters.comune, label: filters.comune }
                                : null
                            }
                            onChange={(selectedOption) => {
                                // Seleziona una sola opzione e aggiorna il filtro
                                setFilters({
                                    ...filters,
                                    comune: selectedOption ? selectedOption.value : '', // Imposta una stringa vuota se non selezionato
                                });
                            }}
                            className="fs-6" // Personalizza la classe per il componente select
                            classNamePrefix="react-select" // Aggiunge un prefisso per lo styling
                            isClearable // Aggiungi questa proprietà per consentire la cancellazione della selezione
                        />
                    </div>
                </div>


                


                


                
                <div className="col-md-12 mb-2 input-group-md">
                    <span className='apt-color-1 bold-text fs-5'>Denominazione</span>


                    {/* <input
                        className="form-control col-md-6"
                        type="text"
                        placeholder="Cerca per denominazione..."
                        value={filters.nome_completo}
                        onChange={(e)
                        => setFilters({ ...filters, nome_completo: e.target.value })}
                    /> */}

                    <div className="mb-2">
                        <Select
                            // Rimuovi isMulti per permettere solo la selezione singola
                            name="denominazione"
                            placeholder="Seleziona la Denominazione"
                            options={options.denominazioni.map(option => ({
                                value: option.value,
                                label: `${option.value}`, // Visualizzazione dell'opzione
                            }))}
                            // value={options.denominazioni
                            //     .find(option => option.value === filters.denominazione) // Filtra per una singola selezione
                            //     ? {
                            //         value: filters.denominazione,
                            //         label: `${filters.denominazione}`
                            //     }
                            //     : null

                            value={filters.nome_completo
                                ? { value: filters.nome_completo, label: filters.nome_completo }
                                : null
                            }

                            onChange={(selectedOption) => {
                                // Gestisci la selezione singola, assegnando il valore a filters.denominazione
                                setFilters({
                                    ...filters,
                                    nome_completo: selectedOption ? selectedOption.value : '', // Imposta una stringa vuota se non selezionato
                                });
                            }}
                            className="fs-6" // Personalizza la classe per il componente select
                            classNamePrefix="react-select" // Aggiunge un prefisso per lo styling
                            isClearable // Consente di cancellare la selezione
                        />
                    </div>

                </div>
            </div>

            <hr />

            <div className="mb-2">
                <h1 className='apt-color-1 bold-text fs-3 mb-3'> Ordina per:</h1>
                <select
                    className="form-select fs-6 default-selected"
                    value={sort}
                    onChange={(e) => setSort(e.target.value)} // Gestisci l'ordinamento separatamente
                >
                    <option className='default' value="default">Default</option>
                    <option value="denominazione_asc">Denominazione (A-Z)</option>
                    <option value="denominazione_desc">Denominazione (Z-A)</option>
                    <option value="prezzo_min">Prezzo (Min)</option>
                    <option value="prezzo_max">Prezzo (Max)</option>
                </select>
            </div>

            <hr />

            <div className="mb-2">
                <h3 className='apt-color-1 bold-text fs-6'>Aggregazioni territoriali</h3>
                {options.aree_geografiche.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`area_geografica-${index}`}
                            checked={filters.area_geografica.includes(option.value)}
                            onChange={() => handleCheckboxChange('area_geografica', option.value)}
                        />
                        <label className="form-check-label d-flex justify-content-between w-100 fs-6" htmlFor={`area_geografica-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted fs-6">({option.count})</span>
                        </label>
                    </div>
                ))}
            </div>



            {/* Repeat similar blocks for other filter categories (e.g., tipologia, prezzo, etc.) */}
            {/* Example for Tipologia */}
            <div className="mb-2">
                <h3 className='apt-color-1 bold-text fs-6'>Tipologia ricettiva</h3>
                {options.tipologie.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`tipologia-${index}`}
                            checked={filters.tipologia.includes(option.value)}
                            onChange={() => handleCheckboxChange('tipologia', option.value)}
                        />
                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`tipologia-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>
                    </div>
                ))}
            </div>


            <div className="mb-2">
                <h3 className='apt-color-1 bold-text fs-6'>Stelle</h3>
                {options.classificazioni.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`classificazione-${index}`}
                            checked={filters.classificazione.includes(option.value)}
                            onChange={() => handleCheckboxChange('classificazione', option.value)}
                        />


                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`classificazione-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>


                    </div>
                ))}
            </div>






            {/* Popup for Price */}
            <div className="mb-2">
                <h3 className='apt-color-1 bold-text fs-6'>Prezzo</h3>
                <div className='d-flex align-items-center mb-2' style={{ cursor: 'pointer' }} onClick={togglePopup}>
                    <FaInfoCircle style={{ fontSize: '14px', color: '#004F78', marginRight: '5px' }} />
                    <span className='info' style={{ color: '#004F78', fontSize: '14px', textDecoration: 'underline' }}>
                        Leggere AVVERTENZE
                    </span>
                </div>

                {isPopupOpen && (
                    <Popup onClose={togglePopup}>
                        <h2 className='apt-color-1'>Avvertenze</h2>
                        <p>Attraverso l’utilizzo del filtro “Prezzo” è possibile selezionare le strutture ricettive attraverso il loro prezzo massimo dichiarato all’APT.<br></br>
                            Si fa presente che il sistema di ricerca individua solo il prezzo maggiore tra tutti quelli massimi dichiarati dalla struttura ricettiva all’APT, a prescindere se il predetto prezzo è riferito a camera, suite o unità abitativa, o al pernottamento per uno o più giorni (come ad esempio quelle strutture ricettive che offrono soggiorni settimanali/mensili), o ad altre opzioni (posti letto, servizi, ecc.).<br></br>
                            Ad esempio, se una struttura ricettiva che ha 10 camere, ha dichiarato che per 9 di esse il prezzo massimo applicabile è di 80,00 euro e per una, la restante, è di 120,00 euro, il sistema mostrerà la struttura solo tra quelle il cui prezzo di riferimento è quello di 120,00 euro e non di 80,00 euro, motivo per cui visualizzerà la struttura solo tra quelle che praticano prezzi massimi “da 101 euro a 200 euro” escludendola nell’altra opzione “da 51 euro a 100 euro”, pur praticando, come già detto, per 9 camere sulle 10 totali il prezzo a 80,00 euro.</p>
                        <button className='btn btn-struttura' onClick={togglePopup} style={{ padding: '10px 20px', fontSize: '16px' }}>
                            Chiudi
                        </button>
                    </Popup>
                )}

                {options.prezzi.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`prezzo-${index}`}
                            checked={filters.prezzo.includes(option.value)}
                            onChange={() => handleCheckboxChange('prezzo', option.value)}
                        />
                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`prezzo-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>
                    </div>
                ))}
            </div>



            <div className="mb-2">
                <h3 className='apt-color-1 bold-text fs-6'>Posizione (Aree Matera) </h3>
                {options.pos_aree.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`pos_aree_mt-${index}`}
                            checked={filters.pos_aree_mt.includes(option.value)}
                            onChange={() => handleCheckboxChange('pos_aree_mt', option.value)}
                        />

                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`pos_aree_mt-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>
                    </div>
                ))}
            </div>






            <div className="mb-2">
                <h3 className='apt-color-1 bold-text fs-6'>Posizione interna alla destinazione</h3>
                {options.pos_interne.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`pos_interna-${index}`}
                            checked={filters.pos_interna.includes(option.value)}
                            onChange={() => handleCheckboxChange('pos_interna', option.value)}
                        />
                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`pos_interna-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>
                    </div>
                ))}
            </div>



            <div className="mb-2">
                <h3 className='apt-color-1 bold-text fs-6'>Posizione esterna alla destinazione</h3>
                {options.pos_esterne.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`pos_esterna-${index}`}
                            checked={filters.pos_esterna.includes(option.value)}
                            onChange={() => handleCheckboxChange('pos_esterna', option.value)}
                        />
                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`pos_esterna-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>
                    </div>
                ))}
            </div>


            {/* <button className="btn search-btn btn-lg apt-bg-color-1 d-flex justify-content-center mt-2" type="submit">
                Cerca
            </button> */}
            <hr></hr>

            <button
                type="button"
                className="btn search-btn apt-bg-color-1 d-flex justify-content-center mt-2 fs-6"
                onClick={resetFilters}

            >
                Azzera filtri
            </button>
        </form>
    );
};

export default SearchFormWithFilters;