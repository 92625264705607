// src/components/Navbar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import logo from '../images/stemma-768x993.png'
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaLinkedin, FaFlickr, FaPinterest,  } from 'react-icons/fa'; // Esempio di icone per i social


function Navbar() {
    const location = useLocation();

    return (
        
        <nav className="navbar navbar-expand-lg navbar-dark apt-bg-color-1">
            <div className="container">

                <Link className="navbar-brand d-flex align-items-center" to="/">
                    <img src={logo} alt="Logo" width="36" height="" className="d-inline-block align-top me-2 " />
                    <span className='logo-text'>Apt Basilicata</span>
                </Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item nav-text me-2">
                            <Link className={`nav-link fs-6 ${location.pathname === '/' ? 'active' : ''}`} aria-current="page" to="/">Dove Dormire</Link>
                        </li>

                        {/* <li className="nav-item nav-text me-2">
                            <Link className={`nav-link fs-6 ${location.pathname === '/about' ? 'active' : ''}`} to="/about">About</Link>
                        </li> */}
                        
                        <li className="nav-item nav-text me-2">
                            <a
                                className={`nav-link fs-6 ${location.pathname === '/sito-istituzionale' ? 'active' : ''}`}
                                href="https://www.aptbasilicata.it/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Sito Istituzionale
                            </a>
                        </li>
                        <li className="nav-item nav-text">
                            <Link className={`nav-link fs-6 ${location.pathname === '/contatti' ? 'active' : ''}`} to="/contatti">Contatti</Link>
                        </li>
                    </ul>

                    <ul className="navbar-nav d-flex flex-row social">
                        <li className="nav-item">
                            <a href="https://www.facebook.com/BasilicataTuristica" className="nav-link" target="_blank" >
                                <FaFacebook /> 
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.instagram.com/basilicataturistica/ " className="nav-link" target="_blank" rel="noreferrer">
                                <FaInstagram />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://twitter.com/Basilicata_Tur" className="nav-link " target="_blank" rel="noreferrer">
                                <FaTwitter />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.youtube.com/c/BasilicataTuristica" className="nav-link" target="_blank" rel="noreferrer">
                                <FaYoutube />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.linkedin.com/company/apt-basilicata" className="nav-link" target="_blank" rel="noreferrer">
                                <FaLinkedin />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.flickr.com/photos/basilicatatravel/" className="nav-link" target="_blank" rel="noreferrer">
                                <FaFlickr />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="https://www.pinterest.it/basilicatatur/" className="nav-link" target="_blank" rel="noreferrer" >
                                <FaPinterest />
                            </a>
                        </li>
                    </ul>
                </div> 
            </div>
        </nav>
    );
}



export default Navbar;