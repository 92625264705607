// src/pages/Contatti.js
import React from 'react';

function Contatti() {
  return (
    <>
      <section className='apt-bg-color-2 pt-2 pb-2 bg-home'>
        <div className='container mt-4'>
          <div>
            <h1 className='title'>Contatti</h1>
            <p className='text-white fs-5'>Richiedi maggiori informazioni</p>
          </div>
        </div>
      </section>

      <div class="container">
        <div class="row gap-4 p-2 mt-5 mb-5">

          <div class="col-sm box-container text-decoration-none shadow-sm p-0">

            <div className='apt-bg-color-1 p-3'>
              <span className='bold-text fs-5 text-white'>Sede di Matera - 75100 (MT)</span>
            </div>


            <div className='p-3'>

              <span><span className='semibold-text'>Indirizzo: </span> Via De Viti De Marco, 9 - Matera (MT)</span> <br></br>
              <span><span className='semibold-text'>Email: </span> matera@aptbasilicata.it</span> <br></br>
              <span><span className='semibold-text'>Telefono: </span> 0835 331983</span><br></br>

              <hr></hr>
              <span>
                <span className='semibold-text'>Orari</span><br></br>
                Lunedì, mercoledì e venerdi: 9:00 - 13:00 <br></br>
                Martedì e giovedi: 9:00 - 13:00 / 16:00 - 17:30
              </span>

              <iframe className='mt-4'
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d259.8736828260786!2d16.603855402577416!3d40.66763288854817!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13477ee058b0f05b%3A0x3fe02674d847fab9!2sAPT%20-%20Agenzia%20di%20Promozione%20Territoriale%20della%20Basilicata%20-%20sede%20di%20Matera!5e0!3m2!1sit!2sus!4v1729758589992!5m2!1sit!2sus"
                width="100%"
                height="350"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Mappa"


              ></iframe>

            </div>
          </div>


          <div class="col-sm box-container text-decoration-none shadow-sm p-0">

            <div className='apt-bg-color-1 p-3'>
              <span className='bold-text fs-5 text-white'>Sede di Potenza - 85100 (PZ)</span>
            </div>

            <div className='p-3'>

              <span><span className='semibold-text'>Indirizzo: </span> Via del Gallitello, 89 - Potenza (PZ)</span> <br></br>
              <span><span className='semibold-text'>Email: </span> potenza@aptbasilicata.it(PZ)</span> <br></br>
              <span><span className='semibold-text'>Tel: </span> 0971 507611</span><br></br>
              <hr></hr>
              <span>
              <span className='semibold-text'>Orari</span><br></br>
                Lunedì, mercoledì e venerdi: 9:00 - 13:00 <br></br>
                Martedì e giovedi: 9:00 - 13:00 / 16:00 - 17:30
              </span>

              <iframe className='mt-4'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d189.2441035263364!2d15.788807122356003!3d40.63196251937035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1338e286dab762d9%3A0xb2c7c6b0d9e2f0ca!2sAPT%20Basilicata%20-%20Agenzia%20di%20Promozione%20Territoriale%20della%20Basilicata%20-%20sede%20di%20Potenza!5e0!3m2!1sit!2sus!4v1729760535677!5m2!1sit!2sus"
                width="100%"
                height="350"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Mappa"

              ></iframe>

            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default Contatti;