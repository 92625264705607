import React from 'react';

const Popup = ({ children, onClose }) => {
  return (
    <>
      <div className="overlay" onClick={onClose}></div>
      <div className="popup">
        {children}
      </div>
    </>
  );
};

export default Popup;
