import React from 'react';

const Popup2 = ({ children, onClose }) => {
  return (
    <>
      <div className="overlay2" onClick={onClose}></div>
      <div className="popup2">
        {children}
      </div>
    </>
  );
};

export default Popup2;
