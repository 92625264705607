// src/pages/About.js
import React from 'react';

function PrivacyPolicy() {
  return (
    <div className='container mt-5 mb-5'>
      <h1 className='semibold-text fs-2'>Privacy Policy</h1>
      <div>
    <h4 className="fs-6">INFORMATIVA RESA AI SENSI DEGLI ART. 13-14 DEL GDPR (GENERAL DATA PROTECTION REGULATION) 2016/679</h4>
    <p>
        Ai sensi della normativa GDPR, il trattamento dei dati sarà improntato ai principi di correttezza, liceità, trasparenza, minimizzazione dei dati e di tutela della Sua riservatezza e dei Suoi diritti.
    </p>
    <p>
        Ai sensi dell'articolo 13 del GDPR 2016/679, pertanto, si forniscono le seguenti informazioni:
    </p>
    <ul>
        <li>I dati personali da lei forniti (tramite registrazione al sito web e/o alle app e/o la compilazione dei moduli presenti sul sito web) saranno oggetto:</li>
        <ul>
            <li>in relazione ad obblighi contrattuali o EULA dei ns. servizi:
                <ol>
                    <li>di trattamento relativo alla formulazione di offerte;</li>
                    <li>di trattamento relativo all'emissione documenti fiscali;</li>
                    <li>di trattamento finalizzato alla redazione di report di natura statistica e per la ricerca e sviluppo;</li>
                    <li>di trattamento finalizzato alla gestione logistica;</li>
                    <li>di trattamento finalizzato alla gestione operativa aziendale;</li>
                    <li>di trattamento finalizzato alla gestione informativa e formativa;</li>
                    <li>di trattamento per finalità legali e gestione di rapporti commerciali;</li>
                </ol>
            </li>
            <li>in relazione ad obblighi di legge:
                <ol>
                    <li>di trattamento connesso alla disciplina IVA (registro fiscale IVA, etc.);</li>
                </ol>
            </li>
        </ul>
    </ul>
    <p>
        Di conseguenza, tenuto conto delle finalità del trattamento come sopra illustrate, il conferimento dei dati è obbligatorio ed il loro mancato, parziale o inesatto conferimento potrà avere, come conseguenza, l'impossibilità di svolgere l’attività di collaborazione con APT Basilicata e di assolvere gli adempimenti contrattuali.
    </p>
    <p>
        Nel caso in cui il soggetto che conferisce i dati abbia un'età inferiore ai 16 anni, tale trattamento è lecito soltanto se e nella misura in cui tale consenso è prestato o autorizzato dal titolare della responsabilità genitoriale per il quale sono acquisiti i dati identificativi e copia dei documenti di riconoscimento.
    </p>
    <p>
        Inoltre, qualora Lei lo acconsenta espressamente, i Suoi dati personali saranno trattati per finalità di marketing che comprendono:
    </p>
    <ul>
        <li>attività promozionali di APT Basilicata, e/o di partner, effettuate sia mediante modalità automatizzate (ad es. email, sms, applicazioni per messaggeria istantanea quali chatbot, ecc.) che mediante modalità non automatizzate (ad es. posta ordinaria, telefono con operatore, ecc.). APT Basilicata potrà trasmetterLe comunicazioni informative e promozionali legate ai servizi e ai prodotti analoghi ed offerti da APT Basilicata e/o da partner (appartenenti alle categorie: software house, società di formazione, società di ricerca e sviluppo, compagnie di assicurazioni, agenzie viaggi, associazioni di promozione turistica e del territorio).</li>
        <li>attività di profilazione, che consistono nell’analisi delle Sue preferenze e ricerche di mercato allo scopo di migliorare l’offerta dei servizi e le informazioni commerciali presentate da APT Basilicata, rendendole più conformi ai Suoi interessi. Tale attività potrà avvenire anche tramite la somministrazione di questionari di soddisfazione e/o l’utilizzo di cookies di profilazione utilizzati durante la navigazione sui siti APT Basilicata.</li>
    </ul>
    <p>
        Il conferimento dei dati facoltativi consentirà a APT Basilicata di migliorare i servizi offerti al fine di renderli sempre più rispondenti agli interessi personali dei propri utenti.
    </p>
    <p>
        Il trattamento sarà espletato sia mediante l’utilizzo di strumenti manuali e/o informatici e telematici con logiche di organizzazione ed elaborazione strettamente correlate alle finalità stesse e comunque in modo da garantire la sicurezza, l'integrità e la riservatezza dei dati stessi nel rispetto delle misure organizzative, fisiche e logiche previste dalle disposizioni vigenti.
    </p>
    <p>
        I dati personali non saranno oggetto di diffusione. I dati personali potranno essere comunicati a soggetti esterni per esclusivo adempimento delle finalità su indicate (consulenti amministrativi, fiscali, aziende legate da obblighi contrattuali con la scrivente, aziende pubbliche e private per espletamento gare di appalto, aziende operanti nel settore del trasporto).
    </p>
    <p>
        I dati personali vengono conservati per tutta la durata del rapporto di collaborazione/fornitura/utilizzo del servizio e, nel caso di revoca e/o altro tipo di cessazione del rapporto, per un periodo limitato specifico per singolo trattamento nel rispetto del principio di limitazione della conservazione e in adempimento degli obblighi di legge (es. fiscali) e per proteggere i diritti del titolare dei dati nelle ipotesi di eventuali controversie legate all’erogazione della prestazione.
    </p>
    <p>
        L’interessato, in qualsiasi momento, può esercitare i diritti come previsto dagli Art. dal 15 al 21 del GDPR. L’esercizio dei suoi diritti potrà avvenire attraverso l’invio di una richiesta mediante email all’indirizzo <a href="mailto:privacy@aptbasilicata.it">privacy@aptbasilicata.it</a>.
    </p>
    <p>
        Il Titolare del trattamento dati è APT Basilicata con sede legale in Via De Viti De Marco 9, 75100 Matera. Il Responsabile del Trattamento è contattabile all’indirizzo: <a href="mailto:privacy@aptbasilicata.it">privacy@aptbasilicata.it</a>. Il DPO è Matteo Maria Visceglia raggiungibile alla mail <a href="mailto:dpo@aptbasilicata.it">dpo@aptbasilicata.it</a>.
    </p>
</div>

    </div>
  );
}

export default PrivacyPolicy;