import React, { useEffect } from 'react';

function CookiePolicy() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://consent.cookiebot.com/d9aac2dc-e0b6-4c22-8c34-f320b9b8c388/cd.js";
    script.id = "CookieDeclaration";
    script.type = "text/javascript";
    script.async = true;

    // Aggiungi lo script al DOM
    document.body.appendChild(script);

    // Pulizia: rimuovi lo script quando il componente viene smontato
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Questo array vuoto significa che l'effetto verrà eseguito solo una volta al montaggio

  return (
    <div className='container mt-5 mb-5'>
      <h1 className='semibold-text fs-2'>Cookie Policy</h1>
      <div id="CookieDeclaration"></div> {/* Cookiebot inietta il contenuto qui */}
    </div>
  );
}

export default CookiePolicy;

