import React from "react";
import { Link } from 'react-router-dom'; // Importa Link da react-router-dom

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-3 mt-auto">
      <div className="container text-center ">
        <span>
          <span className="fs-6">Developed by Doconline</span>
        </span>
        <br></br>
        <span>
          <span className="description">© Apt Basilicata - Tutti i diritti riservati</span>
        </span>
        <br></br>
        <div className="description">
          <Link to="/privacy-policy" className="description text-white me-3">
            Privacy Policy
          </Link>
          <Link to="/cookie-policy" className="description text-white me-3">
            Cookie Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
